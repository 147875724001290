import React from 'react';
import { Layout } from '@common';
import { AllEvents } from '@sections/Events'

const events = () => {
  return (
    <Layout page="Kegiatan">
      <AllEvents />
    </Layout>
  );
};

export default events;
